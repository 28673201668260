// Import react
import React, { useEffect } from 'react';

// Import icons
import { Error, Success } from '../../icons/Circle';

// Import components
import AboutStep from '../../components/AboutStep/AboutStep';
import FormField from '../../components/FormField/FormField';

// Import data and context
import { AboutSteps } from '../../data/aboutSteps';
import { useSchedulingOptions } from '../../context/SchedulingContext';

// Import validators
import { validateScheduleFields } from '../../validators/schedule';

function CustomerData() {
    // Defines the context
    const { scheduling, setScheduling, formErrors, setFormErrors, setSteps } = useSchedulingOptions()

    // Sets the step to return to the page
    useEffect(() => {
        setSteps((prev) => ({
            ...prev,
            next: {
                ...prev.next,
                step: 2
            },
            return: {
                ...prev.return,
                step: 0,
                isVisible: true
            }
        }));
    }, [setSteps]);

    // Handle to check the success of the fields
    const handleSchedulingValidation = (event) => {
        const { name, value } = event.target;

        const errorMessage = validateScheduleFields(name, value);
        setScheduling((prev) => ({
            ...prev,
            customer: {
                ...prev.customer,
                [name]: value,
            },
        }));

        setFormErrors((prev) => ({
            ...prev,
            customer: {
                ...prev.customer,
                [name]: errorMessage,
            },
        }));
    };

    return (
        <>
            <AboutStep data={AboutSteps.registerCustomerData?.about} />

            <form className="scheduling" noValidate>
                <div className="scheduling-fields">
                    <FormField
                        required
                        inputId="name"
                        inputName="name"
                        inputSize="full-size"
                        inputType="text"
                        inputLabel="Qual o seu nome?"
                        inputPlaceholder="Preencha o seu nome completo"
                        inputValue={scheduling.customer.name}
                        inputOnChange={handleSchedulingValidation}
                        feedbackType={formErrors.customer.name ? "error" : "success"}
                        feedbackMessage={formErrors.customer.name}
                        feedbackIcon={formErrors.customer.name ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                    />

                    <FormField
                        inputCode={1}
                        inputId="phone"
                        inputName="phone"
                        inputSize="full-size"
                        inputType="text"
                        inputLabel="E o seu telefone?"
                        inputPlaceholder="Dê preferência para o seu whatsapp"
                        inputValue={scheduling.customer.phone}
                        inputOnChange={handleSchedulingValidation}
                        feedbackType={formErrors.customer.phone ? "error" : "success"}
                        feedbackMessage={formErrors.customer.phone}
                        feedbackIcon={formErrors.customer.phone ? <Error width={17} height={17} /> : <Success width={17} height={17} />}
                    />
                </div>
            </form>
        </>
    );
}

export default CustomerData;

