// Import react
import React, { useState, useEffect } from 'react';

// Import dependencies
import DatePicker from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import { format, parse } from 'date-fns';

// Import CSS
import 'react-datepicker/dist/react-datepicker.css';

// Import icons
import { Error } from '../../icons/Circle';
import { IconCalendar } from '../../icons/Common';

// Import components
import AboutStep from '../../components/AboutStep/AboutStep';

// Import data and context
import { AboutSteps } from '../../data/aboutSteps';
import { useSchedulingOptions } from '../../context/SchedulingContext';

// Import services, functions and messages
import { axiosForRequest } from '../../services/axios.config';
import { formatDateToBrazilian } from '../../functions/converts';
import { ServicesMessages } from '../../messages/servicesMessages';

function SelectDate() {
    // Defines the context
    const { scheduling, setScheduling, formErrors, setFormErrors, setFeedback, setSteps } = useSchedulingOptions()

    // Set the locale for datepicker
    registerLocale("pt-BR", ptBR);

    // Sets the step to return to the page
    useEffect(() => {
        setSteps((prev) => ({
            ...prev,
            next: {
                ...prev.next,
                step: 4
            },
            return: {
                ...prev.return,
                step: 2,
                isVisible: true
            }
        }));
    }, [setSteps]);

    // Treatment for component states
    const [searchTimes, setSearchTimes] = useState({
        availableTimes: [],
        status: {
            isLoading: false,
            isError: {
                status: false,
                message: ''
            },
            isSuccess: {
                status: false,
                message: ''
            }
        }
    })

    // Treatment for component states
    const [selectedDate, setSelectedDate] = useState(null);

    // Search the available times
    const handleSearchTimes = async (event) => {
        event.preventDefault();

        if (selectedDate === null) {
            setFeedback((prev) => ({
                ...prev,
                status: true,
                type: "error",
                message: "Para buscar os horários, é necessário selecionar uma data para agendamento."
            }));

            return;
        }

        setSearchTimes((prev) => ({
            ...prev,
            status: {
                ...prev.status,
                isLoading: true
            }
        }))

        try {
            const response = await axiosForRequest.post('/search-available-times', {
                schedule: {
                    date: scheduling.dateAndTime.date,
                    duration: scheduling.selectedServices.duration
                }
            })

            if (response.data.availableTimes && response.data.availableTimes.length === 0) {
                setScheduling((prev) => ({
                    ...prev,
                    dateAndTime: {
                        ...prev.dateAndTime,
                        formattedDate: formatDateToBrazilian(scheduling.dateAndTime.date)
                    }
                }))

                setFormErrors((prev) => ({
                    ...prev,
                    scheduling: {
                        ...prev.scheduling,
                        isSoldOut: {
                            status: true
                        }
                    }
                }))
            }

            setSearchTimes((prev) => ({
                ...prev,
                availableTimes: response.data.availableTimes,
                status: {
                    ...prev.status,
                    isLoading: false
                }
            }))

        } catch (error) {
            const message = error.response?.data?.message || ServicesMessages.GENERIC.INTERNAL_ERROR;

            setSearchTimes((prev) => ({
                ...prev,
                status: {
                    ...prev.status,
                    isLoading: false,
                    isError: {
                        status: true,
                        message: message
                    }
                }
            }));
        }
    }

    // Saves the selected date
    const handleChange = (date) => {
        // Clean the data when set new date
        setSearchTimes((prev) => ({
            ...prev,
            availableTimes: []
        }))

        // Clean the data when set new date
        setScheduling((prev) => ({
            ...prev,
            dateAndTime: {
                ...prev.dateAndTime,
                date: '',
                formattedDate: '',
                time: '',
                startTime: ''
            }
        }))

        // Clean the data when set new date
        setFormErrors((prev) => ({
            ...prev,
            scheduling: {
                ...prev.scheduling,
                isSoldOut: {
                    ...prev.isSoldOut,
                    status: false
                }
            }
        }))

        // Saves the selected date
        if (date) {
            const formattedDate = format(date, "yyyy-MM-dd");
            setSelectedDate(formattedDate);

            setScheduling((prev) => ({
                ...prev,
                dateAndTime: {
                    ...prev.dateAndTime,
                    date: formattedDate,
                }
            }));
        }
    };

    // Saves the selected time
    const handleTimeChange = (event) => {
        setScheduling((prev) => ({
            ...prev,
            dateAndTime: {
                ...prev.dateAndTime,
                startTime: event.target.value
            }
        }))
    };

    return (
        <>
            <AboutStep data={AboutSteps.selectDate?.about} />

            <form className="scheduling" noValidate>
                <div className="scheduling-fields">
                    <div className="search-date-of-service">
                        <div className="field-date-of-service">
                            <label htmlFor="dateOfService">Data do agendamento</label>
                            <div className="input-with-icon">
                                <DatePicker
                                    locale="pt-BR"
                                    id="dateOfService"
                                    name="dateOfService"
                                    minDate={new Date()}
                                    onChange={handleChange}
                                    dateFormat={'dd/MM/yyyy'}
                                    placeholderText="Selecione uma data"
                                    className="custom-input-date-of-service"
                                    selected={selectedDate ? parse(selectedDate, "yyyy-MM-dd", new Date()) : null}
                                />

                                <IconCalendar width={'17px'} height={'17px'} />
                            </div>
                        </div>

                        <button className="custom-button" onClick={handleSearchTimes} disabled={searchTimes.status.isLoading}>
                            {searchTimes.status.isLoading ? <div className="loading" /> : 'BUSCAR HORÁRIOS'}
                        </button>
                    </div>

                    {scheduling.dateAndTime.startTime && (
                        <span className="selected-info">
                            Hora selecionada:&nbsp;
                            <strong>{scheduling.dateAndTime.startTime}</strong>
                        </span>
                    )}

                    {searchTimes.availableTimes && searchTimes.availableTimes.length > 0 ? (
                        <div className="times-options">
                            {searchTimes.availableTimes.map((time, index) => (
                                <div className="custom-radio" key={index}>
                                    <label className="custom-label">
                                        <input
                                            className="custom-input-radio"
                                            type="radio"
                                            id="startTime"
                                            name="startTime"
                                            value={time}
                                            onChange={handleTimeChange}
                                        />
                                        <span className="custom-check">{time}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    ) : (
                        formErrors.scheduling?.isSoldOut?.status && (
                            <div className="feedback-times-options">
                                <Error className="icon" width={17} height={17} />
                                <p>Os horários para o dia <b>{scheduling.dateAndTime.formattedDate}</b> estão esgotados. Por gentileza, selecione uma nova data.</p>
                            </div>
                        )
                    )}
                </div>
            </form>
        </>
    );
}

export default SelectDate;