// Import react
import React, { useState, useEffect } from 'react';

// Import dependencies
import axios from 'axios';

// Import icons
import { IconNew, IconOptionSelected, IconPromo } from '../../icons/Common';

// Import components
import AboutStep from '../../components/AboutStep/AboutStep';

// Import data and context
import { AboutSteps } from '../../data/aboutSteps';
import { useSchedulingOptions } from '../../context/SchedulingContext';

// Import functions and validators
import { convertToReal } from '../../functions/converts';

// Sets the environment variables
const API_URL = process.env.REACT_APP_API_URL;

function SelectService() {
    // Defines the context
    const { scheduling, setScheduling, setSteps } = useSchedulingOptions()

    // Sets the step to return to the page
    useEffect(() => {
        setSteps((prev) => ({
            ...prev,
            next: {
                ...prev.next,
                step: 3
            },
            return: {
                ...prev.return,
                step: 1,
                isVisible: true
            }
        }));
    }, [setSteps]);

    // Search for registered services
    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await axios.get(`${API_URL}/list-services`);

                setScheduling((prev) => ({
                    ...prev,
                    services: response.data
                }));
            } catch (error) {
                console.log('Erro ao carregar os dados: ', error)
            }
        };

        verifyToken();
    }, [setScheduling]);

    // // Saves the selected services
    const [selectedOptions, setSelectedOptions] = useState([]);
    const handleSelection = (optionId) => {
        setSelectedOptions((prevSelected) =>
            prevSelected.includes(optionId)
                ? prevSelected.filter((id) => id !== optionId)
                : [...prevSelected, optionId]
        );
    };

    // // Saves the information related to the selected options
    useEffect(() => {
        setScheduling((prev) => {
            const selectedServices = prev.services.filter(service => selectedOptions.includes(service.id));

            return {
                ...prev,
                selectedServices: {
                    names: selectedServices.map(service => service.type),
                    duration: selectedServices.reduce((sum, service) => sum + service.duration, 0),
                    value: selectedServices.reduce((sum, service) => sum + parseFloat(service.value), 0)
                }
            };
        });
    }, [selectedOptions, setScheduling]);

    return (
        <>
            <AboutStep data={AboutSteps.selectService?.about} />

            <form className="scheduling" noValidate>
                <div className="scheduling-fields">
                    {scheduling.services.map((option, index) => (
                        <div className="custom-service-option" key={index}>
                            <div className="option-body">
                                <input
                                    className="custom-checkbox"
                                    type="checkbox"
                                    id={`typeOfService-${index}`}
                                    name="typeOfService"
                                    value={option.id}
                                    onChange={() => handleSelection(option.id)}
                                />
                                <label htmlFor={`typeOfService-${index}`} className={`custom-option ${option.tag || ""} ${option.new ? "new" : ""} ${option.promo ? "promo" : ""}`}>
                                    <div className={`option-image ${selectedOptions.includes(option.id) ? "selected" : ""}`}>
                                        {selectedOptions.includes(option.id) && (
                                            <span className="option-image-selected">
                                                <IconOptionSelected width={'45px'} height={'45px'} />
                                            </span>
                                        )}

                                        <img className="option-image" src={option.image} alt={option.type} />
                                    </div>
                                    <div className="option-data">
                                        <div className="option-header">
                                            <h2 className="option-title">{option.type}</h2>
                                            <h4 className="option-description">{option.description}</h4>
                                        </div>

                                        <div className="option-footer">
                                            <h2 className="option-price">{convertToReal(option.value)}</h2>

                                            {option.new ? (
                                                <span className="option-tag new">
                                                    Novidade
                                                    <IconNew width="14px" height="14px" />
                                                </span>
                                            ) : option.promo ? (
                                                <span className="option-tag promo">
                                                    Promoção
                                                    <IconPromo width="14px" height="14px" />
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            </form>
        </>
    );
}

export default SelectService;