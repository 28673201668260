export const AboutSteps = {
    onboarding: {
        step: 0,
        about: [
            "Olá! Seja bem-vinda(o) ao seu espaço exclusivo de agendamentos da Stephanie Nail Designer.",
            "Para iniciarmos, o que você deseja fazer hoje?"
        ]
    },
    registerCustomerData: {
        step: 1,
        about: ["Para continuar, preciso do seu nome e telefone."]
    },
    selectService: {
        step: 2,
        about: [
            "{name}, agora iremos selecionar os serviços que você deseja.",
            "Você pode selecionar um ou mais serviços."
        ]
    },
    selectDate: {
        step: 3,
        about: [
            "{name}, qual o melhor dia para o seu atendimento?",
            "O tempo duração estimado é de {duration} minutos."
        ]
    },
    editSchedulingData: {
        step: 100,
        about: []
    }
};