// Import react
import React from 'react';

// Import CSS
import './aboutStep.scss'

// Import context
import { useSchedulingOptions } from '../../context/SchedulingContext';

// Import functions
import { formatFullName } from '../../functions/converts';

const AboutStep = ({ data }) => {
    // Defines the context
    const { scheduling } = useSchedulingOptions()

    const replacements = {
        "{name}": formatFullName(scheduling.customer.name),
        "{duration}": scheduling.selectedServices.duration
    };

    const replacePlaceholders = (text, replacements) => {
        return text.replace(/{\w+}/g, match => replacements[match] || match);
    };

    return (
        <span className="about-step">
            {data.map((text, index) => (
                <p key={index}>{replacePlaceholders(text, replacements)}</p>
            ))}
        </span>
    )
};

export default AboutStep;