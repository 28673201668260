// Import react
import React, { createContext, useState, useContext } from 'react';

// Defines the context
const SchedulingContext = createContext();

export const SchedulingProvider = ({ children }) => {
    const [scheduling, setScheduling] = useState({
        services: [],
        selectedServices: {
            names: '',
            duration: '',
            value: ''
        },
        customer: {
            name: '',
            phone: ''
        },
        dateAndTime: {
            date: '',
            formattedDate: '',
            time: '',
            startTime: ''
        }
    });

    const [steps, setSteps] = useState({
        current: 0,
        next: {
            step: 0,
            isVisible: true
        },
        return: {
            step: 0,
            isVisible: false
        }
    });

    const [feedback, setFeedback] = useState({
        type: '',
        message: '',
        status: false
    });

    const [formErrors, setFormErrors] = useState({
        customer: {
            name: '',
            phone: ''
        },
        scheduling: {
            date: '',
            time: '',
            isSoldOut: {
                status: false
            }
        }
    });

    const closeFeedback = () => {
        setFeedback((prev) => ({
            ...prev,
            status: false
        }));
    };

    // Auxiliary function to add a service
    const addService = (newService) => {
        setScheduling((prev) => ({
            ...prev,
            services: [...prev.services, newService],
        }));
    };

    // Auxiliary function to remove a service
    const removeService = (serviceId) => {
        setScheduling((prev) => ({
            ...prev,
            services: prev.services.filter((service) => service.id !== serviceId)
        }));
    };

    // // Auxiliary function to set next step
    // const setNextStep = (event, nextStep) => {
    //     event.preventDefault();

    //     setSteps((prev) => ({
    //         ...prev,
    //         current: nextStep
    //     }));
    // };

    return (
        <SchedulingContext.Provider
            value={{ scheduling, setScheduling, formErrors, setFormErrors, steps, setSteps, feedback, setFeedback, closeFeedback, addService, removeService }}>
            {children}
        </SchedulingContext.Provider>
    );
};

export const useSchedulingOptions = () => useContext(SchedulingContext);
