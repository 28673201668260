// Import react
import React from 'react';
// import { useNavigate } from 'react-router-dom';

// import Lottie from 'lottie-react';
// import Player from 'lottie-react';
// import nails from '../animations/loading-nails.json';

// Import components
// import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import ModalOfActionOrFeedback from '../components/ModalOfActionOrFeedback/ModalOfActionOrFeedback';

// Import context

import { useSchedulingOptions } from '../context/SchedulingContext';

// Import SVG icons
import { FeedbackError } from '../icons/Feedback';


// Import functions and validators
import { validateScheduleFields } from '../validators/schedule';

// Import pages
import Onboarding from '../pages/Onboarding/Onboarding';
import CustomerData from '../pages/CustomerData/CustomerData';
import SelectService from '../pages/SelectService/SelectService';
import SelectDate from '../pages/SelectDate/SelectDate';

function Home() {
    // Defines constants to be used
    // const navigate = useNavigate();

    // Defines the context
    const { scheduling, setScheduling, setFormErrors, feedback, setFeedback, closeFeedback, steps, setSteps } = useSchedulingOptions()

    const handleNextStep = (event, step) => {
        event.preventDefault();

        // Defines the step to validate
        const validationMap = {
            0: () => {
                if (steps.next.step === steps.current) {
                    setFeedback((prev) => ({
                        ...prev,
                        status: true,
                        type: "error",
                        message: "Para prosseguir com o agendamento, é necessário selecionar ao mínimo uma opção."
                    }));

                    return false;
                }

                return true;
            },
            1: () => {
                const formErrors = {};

                ['name', 'phone'].forEach((field) => {
                    const error = validateScheduleFields(field, scheduling.customer[field]);

                    if (error) {
                        formErrors[field] = error;
                    }
                });

                if (Object.keys(formErrors).length > 0) {
                    setFormErrors((prev) => ({
                        ...prev,
                        customer: {
                            ...prev.customer,
                            ...formErrors,
                        },
                    }));

                    return false;
                }

                return true;
            },
            2: () => {
                if (scheduling.selectedServices.names.length === 0) {
                    setFeedback((prev) => ({
                        ...prev,
                        status: true,
                        type: "error",
                        message: "Para prosseguir com o agendamento, é necessário selecionar ao mínimo uma opção."
                    }));

                    return false;
                }

                return true;
            },
            3: () => {
                if (scheduling.dateAndTime.date === '') {
                    setFeedback((prev) => ({
                        ...prev,
                        status: true,
                        type: "error",
                        message: "Para prosseguir com o agendamento, selecione uma data e busque por horários disponíveis."
                    }));

                    return false;
                }

                if (scheduling.dateAndTime.startTime === '') {
                    setFeedback((prev) => ({
                        ...prev,
                        status: true,
                        type: "error",
                        message: "Para prosseguir com o agendamento, busque por horários disponíveis e selecione uma opção."
                    }));

                    return false;
                }

                return true;
            }
        };

        // Validations before proceeding
        const isValid = validationMap[steps.current]?.() ?? true;
        if (!isValid) return;

        // Advance if validation passes
        setSteps((prev) => ({
            ...prev,
            current: step,
        }));

        console.log('novo step: ', steps.current)
    }

    const handleReturnStep = (event, step) => {
        event.preventDefault();

        // Set return step
        const returnStep = {
            1: () => {
                setScheduling((prev) => ({
                    ...prev,
                    customer: {
                        ...prev.customer,
                        name: '',
                        phone: ''
                    }
                }));
            },
            2: () => {
                setScheduling((prev) => ({
                    ...prev,
                    selectedServices: {
                        names: '',
                        duration: '',
                        value: ''
                    }
                }));
            },
            3: () => {
                setScheduling((prev) => ({
                    ...prev,
                    dateAndTime: {
                        date: '',
                        formattedDate: '',
                        time: '',
                        startTime: ''
                    }
                }));
            }
        }

        // Execute cleanup to return
        returnStep[steps.current]?.();

        // Return after cleanup
        setSteps((prev) => ({
            ...prev,
            current: step
        }));
    }

    return (
        <>
            <section id="app-scheduling" className="sign">
                <section className="content">
                    <div className="logo" alt="logo" />

                    <section className="step-content">
                        <div className="step-body">
                            {steps.current === 0 && (
                                <Onboarding />
                            )}

                            {steps.current === 1 && (
                                <CustomerData />
                            )}

                            {steps.current === 2 && (
                                <SelectService />
                            )}

                            {steps.current === 3 && (
                                <SelectDate />
                            )}

                            {steps.current === 4 && (
                                console.log('4')
                            )}

                            {steps.current === 100 && (
                                console.log('FUI PRO 100')
                            )}
                        </div>

                        <div className="step-footer">
                            <div className="step-buttons">
                                {steps.next.isVisible && (
                                    <button className="btn btn-default" onClick={(e) => handleNextStep(e, steps.next.step)}>Avançar</button>
                                )}

                                {steps.return.isVisible && (
                                    <button className="btn btn-small-default" onClick={(e) => handleReturnStep(e, steps.return.step)}>Retornar</button>
                                )}
                            </div>
                        </div>
                    </section>
                </section>
            </section>

            <ModalOfActionOrFeedback
                conditionToBeVisible={feedback.status}
                typeOfModal={{ type: "feedback", colorFor: feedback.type }}

                data={{
                    icon: <FeedbackError width={'28px'} height={'28px'} color={'#FFF'} />,
                    message: feedback.message
                }}

                buttons={{ confirm: "ok", onClickConfirm: closeFeedback }}
            />
        </>
    );
}

export default Home;