// Import react
import React, { useEffect } from 'react';

// Import icons
import { IconNewSchedule, IconEditSchedule } from '../../icons/Common';

// Import components
import AboutStep from '../../components/AboutStep/AboutStep';

// Import data and context
import { AboutSteps } from '../../data/aboutSteps';
import { useSchedulingOptions } from '../../context/SchedulingContext';

function Onboarding() {
    // Defines the context
    const { steps, setSteps } = useSchedulingOptions()

    // Sets the step to return to the page
    useEffect(() => {
        setSteps((prev) => ({
            ...prev,
            next: {
                ...prev.next,
                step: 1
            },
            return: {
                ...prev.return,
                step: 0,
                isVisible: false
            }
        }));
    }, [setSteps]);

    // Defines the data to be used
    const nextStepMapping = {
        schedule: AboutSteps.registerCustomerData.step,
        reschedule: AboutSteps.editSchedulingData.step,
    };

    // Defines the available options to be used
    const availableOptionsForProgress = {
        schedule: {
            icon: <IconNewSchedule width={"29px"} height={"29px"} />,
            text: "Agendar novo horário"
        },
        reschedule: {
            icon: <IconEditSchedule width={"29px"} height={"29px"} />,
            text: "Alterar meu horário agendado",
            info: "É permitido a alteração até 48 horas antes do agendamento"
        }
    }

    // Handle for button actions with options to progress
    const handleOptionForProgressClick = (option) => {
        setSteps((prev) => ({
            ...prev,
            next: {
                ...prev.next,
                step: nextStepMapping[option],
                isVisible: true
            },
        }));
    };

    // Checks if the next step configured in the status corresponds to the option
    const isSelected = (option) => steps.next.step === nextStepMapping[option];

    return (
        <>
            <AboutStep data={AboutSteps.onboarding?.about} />

            <div className="options-for-progress column">
                {Object.entries(availableOptionsForProgress).map(([optionKey, optionValue]) => (
                    <button
                        key={optionKey}
                        name={optionKey}
                        className={`btn btn-default ${isSelected(optionKey) ? "selected" : ""}`}
                        onClick={() => handleOptionForProgressClick(optionKey)}
                    >
                        {optionValue.icon}
                        {optionValue.text}
                        {optionValue.info && <p>{optionValue.info}</p>}
                    </button>
                ))}
            </div>
        </>
    );
}

export default Onboarding;